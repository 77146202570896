//Pod boostrapovih 992px preklopimo v mobile view
function mobileView() {

	if ( $( window ).width() < 993 ) {
		//$(".header-main .menu-main").removeClass("open");
		//$(".header-main .search").removeClass("open");
		//$('.header-main .openmenu').removeClass("open");
		$("html").removeClass("noscroll");
		$("body").addClass("mobile-view");
		$("body").removeClass("desktop-view");
		//$("body").removeClass("fixnav");
	}
	else {
		//$(".header-main .menu-main").removeClass("open");
		//$(".header-main .search").removeClass("open");
		//$('.header-main .openmenu').removeClass("open");
		$("html").removeClass("noscroll");
		$("body").removeClass("mobile-view");
		$("body").addClass("desktop-view");
	}
}


$(document).ready(function () {
    
    mobileView();
    
    // SLICK INIT
    
    if ( $( ".single-item" ).length ) {
		//var slideHeight = $('.slick-intro .item img').height();
		//$('.slick-intro').css("height", slideHeight);
		$('.single-item').slick({
			lazyLoad: 'ondemand',
			//lazyLoad: 'progressive',
			slidesToShow: 1,
			slidesToScroll: 1,
			//asNavFor: '.slick-thumbnails',
			//dots: true,
			adaptiveHeight: true,
			//variableWidth: true,
			autoplay: true,
			autoplaySpeed: 5000,
			//fade: true,
			//cssEase: 'ease-in',
			arrows: true,
			infinite: true,
            vertical: true,
			prevArrow: '<div class="nav-arrow nav-prev"></div>',
			nextArrow: '<div class="nav-arrow nav-next"></div>'
			//appendArrows: '.slideshow-nav'
		});
	}
	
	//Match height
	$(function() {
		$('.equal-height-quotes').matchHeight({
			byRow: false,
			property: 'height',
			target: null,
			remove: false
		});
	});
     
      
      // add active to NAV
      $('.nav-toggle').on('click', function () {
        $('.primary-nav').toggleClass('active')
        return false
      })
      
      // add active to toggle
      $('.nav-toggle').on('click', function () {
        $('.nav-toggle').toggleClass('active')
        return false
      })
      
      
      $(document).ready(function () {
        $('.tooltip').click(function(e) {

            $('.tooltip.displayed').removeClass('displayed');

            $(this).addClass('displayed');
            
        });
    });
    
    $('ol li').each(function (index,item){
        $(this).hover(function () {
            $('.n'+(index+1)).addClass('active');
        },
        function () {
            $('.n'+(index+1)).removeClass('active');
        });
        
        $(this).click(function () { 
            $('.tooltip.displayed').removeClass('displayed');
            $('.n'+(index+1)).toggleClass('displayed');
            return false;
          });
    });
    
    $('.tooltip').each(function (index,item){
        $(this).hover(function () {
            $('.li-'+(index+1)).addClass('active');
        },
        function () {
            $('.li-'+(index+1)).removeClass('active');
        });
    });
    
    //animated scrolling
    
    $('.desktop-view #nav li a').click(function(){
		if(($(this).attr('href')) == '#client-quotes'){ $('#client-quotes').addClass('active');
		//$('.single-item').slick();
		}
        $('html, body').animate({
            scrollTop: $( $(this).attr('href') ).offset().top - 98
        }, 500);
        return false;
    });
    
    $('.mobile-view #nav li a').click(function(){
        $('html, body').animate({
            scrollTop: $( $(this).attr('href') ).offset().top
        }, 500);
        return false;
    });
    
    $('ol li a').click(function(){
        $('html, body').animate({
            scrollTop: $( $(this).attr('href') ).offset().top - 98
        }, 500);
        return false;
    });
    
    
    // sticky menu
    
    var stickyNavTop = $('.primary-nav').offset().top;

    var stickyNav = function(){
    var scrollTop = $(window).scrollTop();

    if (scrollTop > stickyNavTop) { 
        $('.primary-nav').addClass('sticky');
    } else {
        $('.primary-nav').removeClass('sticky'); 
    }
    };

    stickyNav();

    $(window).scroll(function() {
      stickyNav();
    });
    
    // add class sticky-menu to body
    
    $(window).scroll(function() {    
        var scroll = $(window).scrollTop();

        if (scroll >= 267) {
            $("body").addClass("sticky-menu");
        } else {
            $("body").removeClass("sticky-menu");
        }
    });
    
});

//resize za 200ms zamikom (na ne refresha prepogosto)
function resizeend() {
	if (new Date() - rtime < delta) {
		setTimeout(resizeend, delta);
	} else {
		timeout = false;
		mobileView();
	}               
}
var rtime;
var timeout = false;
var delta = 200;

$( window ).resize(function() {

	rtime = new Date();
	if (timeout === false) {
		timeout = true;
		setTimeout(resizeend, delta);
	}

	mobileView();

});

$( window ).scroll(function() {


	mobileView();

});